import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Privacy Policy',
    paragraph: 
    <p1></p1>
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                
                  </div>
                <h3 className="mt-0 mb-12">
                Introduction
                  </h3>
                <p className="m-0">
                his privacy notice for 3096 – Blocks ("Company," "we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:
  Download and use our mobile application (3096 – Blocks) or any other application of ours that links to this privacy notice
  Engage with us in other related ways, including any sales, marketing, or events
  Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at support@skyler.media.
                  </p>
              </div>
            </div>

          <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                
                  </div>
                <h3 className="mt-0 mb-12">
                1. WHAT INFORMATION DO WE COLLECT?
                  </h3>
                <p className="m-0">
                The personal information you disclose to us
  
  We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
  
  Sensitive Information. We do not process sensitive information.
  
  Application Data. If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
  
  Mobile Device Data. We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information, system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider, and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID, and information about the features of our application(s) you accessed.
  This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and our internal analytics and reporting purposes.
  
  
  All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
  
  Information automatically collected
  
  In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
  
  We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser, and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
  
  
  The information we collect includes:
  Log and Usage Data. Log and usage data are service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages, and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).
  
  Device Data. We collect device data such as information about your computer, phone, tablet, or another device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.
  
  Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt-out, you may not be able to use certain aspects of the Services.
                  </p>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  
                  </div>
                <h3 className="mt-0 mb-12">
                2. HOW DO WE PROCESS YOUR INFORMATION?
                  </h3>
                <p className="m-0">
                In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with the law. We may also process your information for other purposes with your consent.
  
  We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
  
  
  To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services.
  
  To send you marketing and promotional communications. We may process the personal information you send to us for our marketing purposes if this is per your marketing preferences. You can opt out of our marketing emails at any time. For more information, see "WHAT ARE YOUR PRIVACY RIGHTS?" below).
  
  To deliver targeted advertising to you. We may process your information to develop and display personalized content and advertising tailored to your interests, location, and more.
  
  To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.
  
  To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them.
  
  To determine the effectiveness of our marketing and promotional campaigns. We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.
  
  To save or protect an individual's vital interest. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.
                    </p>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                
                  </div>
                <h3 className="mt-0 mb-12">
                3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
                  </h3>
                <p className="m-0">
                In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.
  
  If you are located in the EU or UK, this section applies to you.
  
  The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on to process your personal information. As such, we may rely on the following legal bases to process your personal information:
  Consent. We may process your information if you have permitted us (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Click here to learn more.
  
  Legitimate Interests. We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described:
  
  Send users information about special offers and discounts on our products and services
  
  Develop and display personalized and relevant advertising content for our users
  
  Analyze how our Services are used so we can improve them to engage and retain users
  
  Support our marketing activities
  
  Diagnose problems and/or prevent fraudulent activities
  
  Understand how our users use our products and services so we can improve user experience
  
  Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.
  
  Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as in situations involving potential threats to the safety of any person. 
                    </p>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                
                  </div>
                <h3 className="mt-0 mb-12">
                4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                  </h3>
                <p className="m-0">
                In Short: We may share information in specific situations described in this section and/or with the following categories of third parties.
  
  Vendors, Consultants, and Other Third-Party Service Providers. We may share your data with third-party vendors, service providers, contractors, or agents ("third parties") who perform services for us or on our behalf and require access to such information to do that work. The categories of third parties we may share personal information with are as follows:
  Ad Networks
  We also may need to share your personal information in the following situations:
  Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                    </p>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                
                  </div>
                <h3 className="mt-0 mb-12">
                5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES? 
                  </h3>
                <p className="m-0">
                In Short: We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise our Services, but are not affiliated with, our Services.
  
  The Services may link to third-party websites, online services, or mobile applications and/or contain advertisements from third parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly, we do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services, or applications. The inclusion of a link to a third-party website, service, or application does not imply an endorsement by us. We cannot guarantee the safety and privacy of the data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions.
                    </p>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                
                  </div>
                <h3 className="mt-0 mb-12">
                6. HOW LONG DO WE KEEP YOUR INFORMATION?
                  </h3>
                <p className="m-0">
                In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
  
  We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).
  
  When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                    </p>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
               
                  </div>
                <h3 className="mt-0 mb-12">
                7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                  </h3>
                <p className="m-0">
                In Short: We aim to protect your personal information through a system of organizational and technical security measures.
  
  We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, the transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
                    </p>
              </div>
            </div>
            
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">

                  </div>
                <h3 className="mt-0 mb-12">
                8. DO WE MAKE UPDATES TO THIS NOTICE?
                  </h3>
                <p className="m-0">
                In Short: Yes, we will update this notice when necessary to stay compliant with relevant laws.
  
  We may update this privacy notice from time to time. The updated version will contain a "Revised" date, and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to keep informed of how we are protecting your information.
                  </p>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                
                  </div>
                <h3 className="mt-0 mb-12">
                9. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </h3>
                <p className="m-0">
                If you have questions or comments about this notice, email us at privacy@skyler.media.
                  </p>
              </div>
            </div>

          </div>
        </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;